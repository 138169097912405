import React from "react";

import { Container, Form, Button, Row, Col, Image } from 'react-bootstrap';

import { Layout } from '../components/layout';
import { Masthead } from '../components/masthead';
import Section from '../components/section'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'


import "../style/global.scss"

import mastheadImage from '../images/contact/masthead.jpg'
import founderImage from '../images/contact/founder.jpeg'


export default function Index() {
  return (
    <Layout title="Home | J Squared Design Services">
      <Masthead>
        <Masthead.Background>
          <img className="w-100 h-100" style={{ 'objectFit': 'cover' }} src={mastheadImage} />
        </Masthead.Background>
      </Masthead>
      <Container>
        <Row>
          <Col md="8">
            <Section><Container>
              <Row>
                <Col md="12">
                  <h2 className="text-center">Send a Message</h2>
                  <Form>
                    <Row>
                      <Col md="6">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control type="text" />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control type="text" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="text" />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control type="text" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Company</Form.Label>
                          <Form.Control type="text" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Message</Form.Label>
                          <Form.Control as="textarea" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Container></Section>
          </Col>
          <Col md="4">
            <Section><Container>
              <Row>
                <Col md="12">
                  <h2 className="text-center">Contact Us Directly</h2>
                  <div className="ml-5">
                    <FontAwesomeIcon icon={faPhone} className="mx-2" />
                    <a href="tel:620-724-3392">620-724-3392</a>
                  </div>
                  <div className="ml-5">
                    <FontAwesomeIcon icon={faEnvelope} className="mx-2" />
                    <a href="mailto:info@jsquaredds.com">info@jsquaredds.com</a>
                  </div>
                </Col>
              </Row>
            </Container></Section>
            <Section><Container>
              <Row>
                <Col md="12">
                  <h2 className="text-center">Connect with our Founder</h2>
                  <div className="text-center">
                    <Image className="my-3" src={founderImage} roundedCircle />
                    <h5>Justin Jagels</h5>
                    <a href="https://www.linkedin.com/in/justinjagels"><FontAwesomeIcon size="2x" color="blue" icon={faLinkedin} className="mx-2" /></a>
                  </div>
                </Col>
              </Row>
            </Container></Section>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}